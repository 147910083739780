// import React from 'react';
// import { Box, useMediaQuery } from '@mui/material';
// import NameAndAddressCard from './components/NameAndAddressCard';
// import OrganizationStatsCard from './components/OrganizationStatsCard';
// import OrganizationContactCard from './components/OrganizationContactCard';
// import AdministratorsCard from './components/AdministratorsCard';
// import LogoCard from './components/LogoCard';
// import AdminStructure from './common/AdminStructure';
// import useTranslations from '../../hooks/useTranslation';
// import EditProfile from './components/EditProfile';
// import GroupTitle from './components/GroupTitle';

// const Settings = () => {
//   const { translate } = useTranslations();
//   const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

//   const searchHandler = value => {
//     // if (value.length === 0) {
//     //   setGroups(searchGroups);
//     // } else {
//     //   const filteredGroups = searchGroups.filter(group => group?.name?.toLowerCase().includes(value.toLowerCase()));
//     //   setGroups(filteredGroups);
//     // }
//   };

//   return (
//     <>
//       <div
//         style={{
//           backgroundImage: 'none !important',
//           backgroundColor: '#f9f9f9',
//           height: '100vh',
//           width: '100vw',
//           overflow: 'hidden'
//         }}
//       >
//         <AdminStructure heading={translate('Settings')} searchHandler={searchHandler}>
//           <div style={{overflowY: "auto", height: "calc(100vh - 64px)"}}>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexWrap: "wrap",
//                 gap: 2,
//                 px: 2,
//                 justifyContent: "space-between",
//                 width: "95%",
//                 pb: 5
//               }}
//             >
//               <Box width="54%" display="flex" flexDirection="column" gap={2}>
//                 <NameAndAddressCard />
//                 <OrganizationContactCard />
//                 <GroupTitle />
//                 <LogoCard />
//               </Box>
//               <Box width="42%" display="flex" flexDirection="column" gap={2}>
//                 <OrganizationStatsCard />
//                 <AdministratorsCard />
//               </Box>
//               {/* <EditProfile /> */}
//             </Box>
//           </div>
//         </AdminStructure>
//       </div>
//     </>
//   );
// };

// export default Settings;



// Commented code for localization change for particular org

import React, {useState} from 'react';
import {Box, Avatar, Typography, IconButton, useMediaQuery} from '@mui/material';
import useAuthStore from '../../store/authStore';
import EditIcon from '@mui/icons-material/Edit';
import {BASE_URL, CONSTANTS, FILE_PATH_PROFILE_URL} from '../../constants';
import {capitalizeWords, isValidFile, validatePhone} from '../../utils';
import FormWrapper from '../../components/common/StyledFormWrapper';
import toast from 'react-hot-toast';
import AdminStructure from './common/AdminStructure';
import AdminTextInput from './components/AdminTextInput';
import useTranslations from '../../hooks/useTranslation';
import StyledSelectInput from './components/StyledSelectInput';
import {updateOrganizationDefaultLanguage} from '../../api';

function Settings() {
  const {translate, switchLanguage} = useTranslations();
  const {user, token, updateUserData, selectedOrganization, updateOrgDefaultLanguage} = useAuthStore(state => state);
  const [editUserData, setEditUserData] = useState({
    id: user?.id,
    name: user?.first_name + ' ' + user?.last_name,
    avatar: user?.avatar,
    mobile_number: user?.mobile_number
  });
  const [defaultLang, setDefaultLang] = useState(selectedOrganization.default_language);
  const [lang, setLang] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileExtensionName, setFileExtensionName] = useState([]);
  const userId = user?.id;

  const handleInputChange = e => {
    if (e.target.value.length <= 64) {
      setEditUserData(prevData => ({
        ...prevData,
        name: capitalizeWords(e.target.value)
      }));
    }
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handlePhoneInputChange = e => {
    const phoneNumber = e.target.value;
    setEditUserData(prevData => ({
      ...prevData,
      mobile_number: phoneNumber
    }));
  };
  const handleApplyLanguageChange = async event => {
    const selectedLang = event.target.value;
    setLang(selectedLang);

    try {
      const res = await updateOrganizationDefaultLanguage(selectedOrganization.id, selectedLang);
      updateOrgDefaultLanguage({default_language: selectedLang});
      switchLanguage(Number(selectedLang));
      toast.success(translate('Language updated successfully!'));
    } catch (error) {
      toast.error(translate('Failed to update Language!'), {
        id: 'error_message'
      });
      console.error(error);
    }
  };
  const imageChangeHandler = e => {
    let file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(CONSTANTS.MESSAGES.FILE_LARGE_IMAGE);
      return;
    }
    if (isValidFile('image', file)) {
      setEditUserData(prevData => ({
        ...prevData,
        avatar: e.target.files[0]
      }));
      let fileExtension = e.target.files[0].name.split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      setFileExtensionName(fileExtension);
    } else {
      toast.error(CONSTANTS.MESSAGES.IMAGE_ALLOWED_ERROR, {
        id: 'toast_message'
      });
    }
  };

  const handleUpdate = async () => {
    if (editUserData?.name?.trim().length === 0) {
      toast.error(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED, {
        id: 'toast_message'
      });
      return;
    }
    if (editUserData?.mobile_number && !validatePhone(editUserData?.mobile_number)) {
      toast.error(CONSTANTS.MESSAGES.VALID_PHONE_NUMBER, {
        id: 'toast_message'
      });
      return;
    }
    try {
      setButtonLoading(true);
      const formData = new FormData();
      formData.append('first_name', editUserData?.name?.trim().split(' ')[0] || '');
      formData.append('last_name', editUserData?.name?.trim().split(' ')[1] || '');
      formData.append('mobile_number', editUserData?.mobile_number || '');
      if (editUserData?.avatar && typeof editUserData?.avatar !== 'string') {
        formData.append('profile_avatar', editUserData?.avatar);
      }

      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: token
        },
        body: formData
      };

      const response = await fetch(`${BASE_URL}/users/${userId}`, requestOptions);
      const data = await response.json();
      if (data?.status === 200) {
        let fileName = '';
        if (editUserData?.avatar && typeof editUserData.avatar !== 'string') {
          updateUserData({avatar: data?.data?.avatar ? data?.data?.avatar : ''});
          fileName = data?.data?.avatar;
        } else {
          fileName = data?.data?.avatar;
        }
        fileName = fileName == {} ? '' : fileName;

        updateUserData({
          first_name: editUserData?.name.trim().split(' ')[0] || '',
          last_name: editUserData?.name.trim().split(' ')[1] || '',
          mobile_number: editUserData?.mobile_number ? editUserData?.mobile_number.trim() : '',
          avatar: data?.data?.avatar
        });
        toast.success(data.message, {
          id: 'toast_message'
        });
      } else {
        toast.error(CONSTANTS.MESSAGES.SOMETHING_WRONG, {
          id: 'error_message'
        });
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: 'none !important',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          width: '100vw',
          overflowY: isSmallScreen ? 'auto' : 'hidden'
        }}
      >
        <AdminStructure heading="Settings">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={4}
            sx={{
              height: '80vh',
              overflowY: 'auto',
              paddingX: {
                xs: '20px',
                sm: '30px'
                // md: "30px",
              }
            }}
          >
            <>
              <FormWrapper buttonInputs={[{text: 'Update', onClick: handleUpdate}]} headingText="Edit Profile" headingTextColor="black" loading={buttonLoading}>
                <IconButton>
                  <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
                    <div style={{position: 'relative'}}>
                      <Avatar
                        alt={editUserData?.name}
                        src={editUserData?.avatar ? (typeof editUserData?.avatar === 'object' ? URL.createObjectURL(editUserData?.avatar) : `${FILE_PATH_PROFILE_URL}/${editUserData?.avatar}`) : ''}
                        style={{
                          margin: '10px',
                          width: '80px',
                          height: '80px'
                        }}
                      />
                      <EditIcon
                        style={{
                          color: 'black',
                          position: 'absolute',
                          bottom: 22,
                          right: 20,
                          transform: 'translate(50%, 50%)',
                          zIndex: 1,
                          backgroundColor: 'whitesmoke',
                          borderRadius: '50%',
                          padding: '2px',
                          border: '.5px solid black'
                        }}
                      />
                    </div>
                    <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
                  </label>
                </IconButton>
                <Typography py={2} fontSize={'16px'} color="black">
                  {user?.email}
                </Typography>
                <AdminTextInput fullWidth value={editUserData?.name} bottomLabel={translate('Name')} onChange={handleInputChange} autoComplete="off" labelVariant="body2" colorBlack={'text-black'} />
                <AdminTextInput fullWidth value={editUserData?.mobile_number} bottomLabel={translate('Phone Number')} onChange={handlePhoneInputChange} maxLength={CONSTANTS.MOBILE_NUMBER_MAX_LENGTH} autoComplete="off" labelVariant="body2" colorBlack={'text-black'} labelTopGap={2.5} />
              </FormWrapper>
              <StyledSelectInput value={defaultLang} onChange={handleApplyLanguageChange} fullWidth variant="outlined" optionArray={Object.values(CONSTANTS.LANG).map(({key, value, name}) => ({value: key, label: name}))} bottomLabel={translate('Preferred Language')} labelTopGap={-2.5} />
            </>
          </Box>
        </AdminStructure>
      </div>
    </>
  );
}

export default Settings;
