import React from 'react';
import {Grid, MenuItem} from '@mui/material';
import StyledSelectInput from '../admin/components/StyledSelectInput';
import useTranslations from '../../hooks/useTranslation';
import AdminTextInput from '../admin/components/AdminTextInput';
import { CONSTANTS } from '../../constants';

const AddOrganization = (({formData,setFormData}) => {
  const {translate} = useTranslations();
  const handleChange = e => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <form style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <Grid item xs={12}>
        <AdminTextInput fullWidth name="name" bottomLabel={translate('Organization Name')} value={formData.name} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput fullWidth name="contact_name" bottomLabel={translate('Organization Contact')} value={formData.contact_name} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput fullWidth name="contact_email" bottomLabel={translate('Email')} value={formData.contact_email} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
      <Grid item xs={12} mt={-1}>
        <StyledSelectInput
          name="country"
          value={formData.country}
          onChange={e => handleChange({target: {name: 'country', value: e.target.value}})}
          fullWidth
          variant="outlined"
          optionArray={CONSTANTS.COUNTRIES.map(country => ({
            label: country,
            value: country
          }))}
          bottomLabel={translate('Country')}
          labelTopGap={-2.5}
        >
          {CONSTANTS.COUNTRIES.map(country => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </StyledSelectInput>
      </Grid>
    </form>
  );
});

export default AddOrganization;
