import {saveAs} from 'file-saver';
import ExcelJS from 'exceljs';
import {CONSTANTS} from '../constants';

// to check if phone number is valid or not
const validatePhone = phone => {
  const phoneRegex = CONSTANTS.REGEX.PHONE_NUMBER;
  return phoneRegex.test(phone);
};

// to check if special characters includes in string
const isSpecialCharacterIncludes = str => {
  const strRegex = /[!@#$₹%^&*()+\=\[\]{}\\|<>\/?]/;
  return strRegex.test(str);
};

// to check if zipcode is valid or not
const isValidZipCode = zipCode => {
  const usZipCodeRegex = /^\d{5}(?:-\d{4})?$/;
  return usZipCodeRegex.test(zipCode);
};

const isValidLocation = location => {
  const locationRegex = /^[a-zA-Z\s]+$/;
  return locationRegex.test(location.trim()); // Trim spaces and test against regex
};

// to check if email is valid or not
const validateEmail = email => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  return emailRegex.test(email);
};

// to check if object property is empty or not
function isEmptyObjectValues(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length === 0) {
        return true;
      }
    } else if (typeof obj[key] === 'string') {
      if (obj[key].trim() === '') {
        return true;
      }
    }
  }
  return false;
}


function disableDatesByWeekDay(dayOfWeek, date) {
  let dayOfWeeks = dayOfWeek;
  let weekNo;
  if (dayOfWeeks === 'monday') {
    weekNo = 1;
  }
  if (dayOfWeeks === 'tuesday') {
    weekNo = 2;
  }
  if (dayOfWeeks === 'wednesday') {
    weekNo = 3;
  }
  if (dayOfWeeks === 'thursday') {
    weekNo = 4;
  }
  if (dayOfWeeks === 'friday') {
    weekNo = 5;
  }
  if (dayOfWeeks === 'saturday') {
    weekNo = 6;
  }
  if (dayOfWeeks === 'sunday') {
    weekNo = 0;
  }
  return new Date(date).getDay() !== weekNo;
}

function allFieldsHaveValue(obj) {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      return false;
    }
  }
  return true;
}

// to check what file type
function isValidFile(type, file) {
  if (type === 'image') {
    if (file.type === 'image/png' || file?.type === 'image/jpg' || file?.type === 'image/jpeg' || file?.type === 'image/svg' || file?.type === 'image/webp' || file?.type === 'image/*') {
      return true;
    } else {
      return false;
    }
  } else {
    if (file.type === 'application/pdf' || file?.type === 'application/vnd.ms-excel' || file?.type === '.xls' || file?.type === '.xlsx' || file?.type === '.csv') {
      return true;
    } else {
      return false;
    }
  }
}

// timer formatter
function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// capitalize the first character
function capitalizeWords(str) {
  const words = str.split(' ');
  const capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return '';
    }
  });
  return capitalizedWords.join(' ');
}

function mergeConsecutiveSteps(data) {
  const mergedData = [];
  for (let i = 0; i < data.length; i += 2) {
    if (i + 1 < data.length) {
      const idPair = `${data[i].id},${data[i + 1].id}`;
      const questionPair = `${data[i].question},${data[i + 1].question}`;
      const stepCount = `${i + 1},${i + 2}`;
      const updated_by = `${data[i].updated_by}`;
      mergedData.push({
        id: idPair,
        question: questionPair,
        stepCount,
        updated_by
      });
    } else {
      // If there's only one item left, just push it as is
      mergedData.push(data[i]);
    }
  }
  return mergedData;
}

// to get the overall growth report
function getOverallGrowthReport(reportData) {
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    'Overall Growth Report',
    {
      headerFooter: {
        firstHeader: 'First Year Disciple Acquisition & Progression'
      }
    },
    {
      properties: {tabColor: '#FF808080'}
    }
  );
  for (let i = 1; i <= 17; i++) {
    let column = worksheet.getColumn(i);
    column.width = 20;
  }

  // Add headers to the worksheet
  let rowh1 = worksheet.addRow(['Overall Growth']);
  rowh1.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'FF808080'}
  };
  rowh1.alignment = {horizontal: 'center', vertical: 'middle'};
  worksheet.addRow(['']);
  let rowd1 = worksheet.addRow(['', '', '', 'D1', '', '', 'D2', '', '', 'D3', '', '', 'Stalled/Dropped', '', '']);
  rowd1.alignment = {horizontal: 'center', vertical: 'middle'};
  rowd1.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };
  rowd1.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'}
  };
  let rowd2 = worksheet.addRow(['Year', 'Total', '', 'Started', 'Finished', '', 'Started', 'Finished', '', 'Started', 'Finished', '', 'No.', 'Percentage of Started', '']);
  rowd2.alignment = {horizontal: 'center', vertical: 'middle'};
  rowd2.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };
  rowd2.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'}
  };

  worksheet.mergeCells('A1:N1');

  // Merge cells for D1, D2, and D3
  worksheet.mergeCells('D2:E2');
  worksheet.mergeCells('G2:H2');
  worksheet.mergeCells('J2:K2');
  worksheet.mergeCells('M2:N2');

  // Iterate over the properties of reportData
  Object.entries(reportData).forEach(([year, yearData]) => {
    worksheet.addRow([year, yearData?.Total, '', yearData?.D1_started, yearData?.D1_finished, '', yearData?.D2_started, yearData?.D2_finished, '', yearData?.D3_started, yearData?.D3_finished, '', yearData?.stalled_count, Number(yearData?.stalled_percent).toFixed(2) + ' %', '']);
  });

  // Generate the Excel file
  workbook.xlsx.writeBuffer().then(buffer => {
    // Convert buffer to Blob
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Save Blob as file
    saveAs(blob, 'Overall_Growth_Report.xlsx');
  });
}

// to get the yearly  report
function getYearReport(reportData) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Yearly Report');

  for (let i = 1; i <= 17; i++) {
    let column = worksheet.getColumn(i);
    column.width = 20;
  }

  worksheet.addRow([]);

  let rowh1 = worksheet.addRow(['First Year Disciple Acquisition & Progression']);
  rowh1.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'FF808080'} // Gray color
  };
  rowh1.alignment = {horizontal: 'center'};

  worksheet.mergeCells('A2:Q2');
  worksheet.addRow([]);
  let rowd11 = worksheet.addRow(['', '', '', 'Finished', '', '', 'Dropped', '', '', 'Moved to D2', '', '', 'Stalled', '']);

  rowd11.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'} // Gray color
  };

  // Align the text to center
  rowd11.alignment = {horizontal: 'center'};
  rowd11.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };
  let rowd12 = worksheet.addRow(['Year', 'Started', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '']);

  rowd12.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'} // Gray color
  };

  // Align the text to center
  rowd12.alignment = {horizontal: 'center'};
  rowd12.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };
  worksheet.mergeCells('D4:E4');
  worksheet.mergeCells('G4:H4');
  worksheet.mergeCells('J4:K4');
  worksheet.mergeCells('M4:N4');

  const addDataForD1 = (worksheet, startRow, reportData, prefix) => {
    const data = reportData[prefix];
    if (data) {
      Object.entries(data).forEach(([key, values]) => {
        worksheet.addRow([key, values?.total ?? '0', '', values?.finished_count ?? '0', values?.finished_percent ? Number(values?.finished_percent).toFixed(2) + '%' : '0', '', values?.dropped_count ?? '0', values?.dropped_percent ? Number(values?.dropped_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_count ? values?.moved_D2_count : '0', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + '%' : '0', '', '']);
      });
    }
  };
  addDataForD1(worksheet, 1, reportData, 'D1');
  worksheet.addRow([]);
  worksheet.addRow([]);

  let rowh2 = worksheet.addRow(['Second Year Disciple Progression']);
  rowh2.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'FF808080'} // Gray color
  };
  rowh2.alignment = {horizontal: 'center'};
  worksheet.mergeCells('A10:Q10');

  worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);

  let rowd21 = worksheet.addRow(['', '', '', 'Finished', '', '', 'Dropped', '', '', 'Moved to D3', '', '', 'Gap Year Making Disciples', '', '', 'Stalled', '']);
  rowd21.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'} // Gray color
  };
  rowd21.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };
  // Align the text to center
  rowd21.alignment = {horizontal: 'center'};
  let rowd22 = worksheet.addRow(['Year', 'Started', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '']);
  rowd22.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'} // Gray color
  };
  rowd22.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };
  // Align the text to center
  rowd22.alignment = {horizontal: 'center'};
  worksheet.mergeCells('D12:E12');
  worksheet.mergeCells('G12:H12');
  worksheet.mergeCells('J12:K12');
  worksheet.mergeCells('M12:N12');
  worksheet.mergeCells('P12:Q12');

  const addDataForD2 = (worksheet, startRow, reportData, prefix) => {
    const data = reportData[prefix];
    if (data) {
      Object.entries(data).forEach(([key, values]) => {
        worksheet.addRow([key, values?.total ?? '0', '', values?.finished_count ? values?.finished_count : '0', values?.finished_percent ? Number(values?.finished_percent).toFixed(2) + '%' : '0', '', values?.dropped_count ?? '0', values?.dropped_percent ? Number(values?.dropped_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_count ?? '0', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + '%' : '0', '', '']);
      });
    }
  };
  addDataForD2(worksheet, 10, reportData, 'D2');

  worksheet.addRow([]);
  worksheet.addRow([]);

  let rowh3 = worksheet.addRow(['Third Year Disciple Progression']);
  rowh3.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'FF808080'} // Gray color
  };
  rowh3.alignment = {horizontal: 'center'};
  worksheet.mergeCells('A18:Q18');

  worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);

  let rowd31 = worksheet.addRow(['', 'Started', '', '', '', 'Finished', '', '', 'Dropped', '', '', 'Going to Make', '', '', 'Stalled', '']);
  rowd31.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'} // Gray color
  };
  rowd31.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };

  // Align the text to center
  rowd31.alignment = {horizontal: 'center'};
  let rowd32 = worksheet.addRow(['Year', 'From D2', 'From Gap Year', 'Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '', '']);

  rowd32.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'cccacf'} // Gray color
  };
  rowd32.border = {
    top: {style: 'thin'},
    left: {style: 'thin'},
    right: {style: 'thin'},
    bottom: {style: 'thickThinRest'}
  };

  // Align the text to center
  rowd32.alignment = {horizontal: 'center'};

  worksheet.mergeCells('F20:G20');
  worksheet.mergeCells('I20:J20');
  worksheet.mergeCells('L20:M20');
  worksheet.mergeCells('O20:P20');
  worksheet.mergeCells('B20:C20');

  const addDataForD3 = (worksheet, startRow, reportData, prefix) => {
    const data = reportData[prefix];
    if (data) {
      Object.entries(data).forEach(([key, values]) => {
        worksheet.addRow([key, values?.total ?? '0', values?.finished_count ?? '0', values?.finished_percent ? Number(values?.finished_percent).toFixed(2) + '%' : '0', '', values?.dropped_count ?? '0', values?.dropped_percent ? Number(values?.dropped_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_count ?? '0', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + '%' : '0', '', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + '%' : '0', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + '%' : '0', '', '']);
      });
    }
  };

  addDataForD3(worksheet, 18, reportData, 'D3');

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'Yearly_Report.xlsx');
  });
}

// to get the Organisational report
function getOrganisationReport(reportData) {
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  for (const key in reportData.data) {
    const worksheet = workbook.addWorksheet(key, {
      properties: {tabColor: 'gray'}
    });

    for (let i = 1; i <= 17; i++) {
      let column = worksheet.getColumn(i);
      column.width = 16;
    }

    // Add headers to the worksheet

    const Sustainable = reportData.data[key];
    const data = Sustainable['D1'];
    let rowh1 = worksheet.addRow([key + ' - First Year Disciple Acquisition & Progression']);
    rowh1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF808080'} // Gray color
    };
    rowh1.alignment = {horizontal: 'center'};
    worksheet.mergeCells('A1:Q1');

    const rowH1 = worksheet.addRow(['', '', '', 'Finished', '', '', 'Dropped', '', '', 'Moved to D2', '', '', 'Stalled', '', '']);
    const rowH2 = worksheet.addRow(['Year', 'Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '']);

    rowH1.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'},
      bottom: {style: 'thickThinRest'}
    };
    rowH2.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'},
      bottom: {style: 'thickThinRest'}
    };
    rowH1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccacf'} // Gray color
    };
    rowH2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccacf'} // Gray color
    };

    if (data) {
      Object.entries(data).forEach(([key, values]) => {
        worksheet.addRow([key, values?.total ?? '', '', values?.finished_count ?? '', Number(values?.finished_percent).toFixed(2) + ' %' ?? '0 %', '', values?.dropped_count ?? '', Number(values?.dropped_percent).toFixed(2) + ' %' ?? '0 %', '', values?.moved_D2_count ?? '', values?.moved_D2_percent ? Number(values?.moved_D2_percent).toFixed(2) + ' %' : '0 %', '', values?.stalled_count ?? '', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + ' %' : '0 %', '', '']);
      });
    }
    worksheet.mergeCells('D2:E2');
    worksheet.mergeCells('G2:H2');
    worksheet.mergeCells('J2:K2');
    worksheet.mergeCells('M2:N2');

    worksheet.addRow([]);
    worksheet.addRow([]);

    let rowh2 = worksheet.addRow([key + ' - Second Year Disciple Progression']);
    rowh2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF808080'} // Gray color
    };
    rowh2.alignment = {horizontal: 'center'};
    worksheet.mergeCells('A8:Q8');

    const D2data = Sustainable['D2'];

    const rowH3 = worksheet.addRow(['', '', '', 'Finished', '', '', 'Dropped', '', '', 'Gap Year Making Disciples', '', '', 'Stalled']);
    const rowH4 = worksheet.addRow(['Year', 'Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '']);

    rowH3.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'},
      bottom: {style: 'thickThinRest'}
    };
    rowH4.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'},
      bottom: {style: 'thickThinRest'}
    };
    rowH3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccacf'} // Gray color
    };
    rowH4.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccacf'} // Gray color
    };
    if (D2data) {
      Object.entries(D2data).forEach(([key, values]) => {
        worksheet.addRow([key, values?.total ?? '', '', values?.finished_count ?? '', Number(values?.finished_percent).toFixed(2) + ' %' ?? '0 %', '', values?.dropped_count ?? '', Number(values?.dropped_percent).toFixed(2) + ' %' ?? '0 %', '', values?.gap_count ?? '', values?.gap_percent ? Number(values?.gap_percent).toFixed(2) + ' %' : '0 %', '', values?.stalled_count ?? '', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + ' %' : '0 %', '', '']);
      });
    }

    // Merge cells for D1, D2, and D3
    worksheet.mergeCells('D9:E9');
    worksheet.mergeCells('G9:H9');
    worksheet.mergeCells('J9:K9');

    // Merge cells for Stalled/Dropped
    worksheet.mergeCells('M9:N9');

    worksheet.addRow([]);
    worksheet.addRow([]);

    let rowh3 = worksheet.addRow([key + ' - Third Year Disciple Progression']);
    rowh3.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF808080'} // Gray color
    };
    rowh3.alignment = {horizontal: 'center'};
    worksheet.mergeCells('A15:Q15');
    const D3data = Sustainable['D3'];

    let rowH5 = worksheet.addRow(['', '', '', 'Finished', '', '', 'Dropped', '', '', 'Gap Year Making Disciples', '', '', 'Going To Make', '', '', 'Stalled']);
    let rowH6 = worksheet.addRow(['Year', 'Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Total', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '', 'No.', 'Percent of Finished', '']);
    rowH5.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'},
      bottom: {style: 'thickThinRest'}
    };
    rowH6.border = {
      top: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'},
      bottom: {style: 'thickThinRest'}
    };
    rowH5.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccacf'} // Gray color
    };
    rowH6.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccacf'} // Gray color
    };
    if (D3data) {
      Object.entries(D3data).forEach(([key, values]) => {
        worksheet.addRow([key, values?.total ?? '', '', values?.finished_count ?? '', Number(values?.finished_percent).toFixed(2) + ' %' ?? '0 %', '', values?.dropped_count ?? '', Number(values?.dropped_percent).toFixed(2) + ' %' ?? '0 %', '', values?.from_D2 ?? '', values?.from_gap_year ? values?.from_gap_year + ' %' : '0 %', '', values?.going_to_make_count ?? '', Number(values?.going_to_make_percent).toFixed(2) + ' %' ?? '0 %', '', values?.stalled_count ?? '', values?.stalled_percent ? Number(values?.stalled_percent).toFixed(2) + ' %' : '0 %', '', '']);
      });
    }

    // Merge cells for D1, D2, and D3
    worksheet.mergeCells('D16:E16');
    worksheet.mergeCells('G16:H16');
    worksheet.mergeCells('J16:K16');

    // Merge cells for Stalled/Dropped
    worksheet.mergeCells('M16:N16');
    worksheet.mergeCells('P16:Q16');
  }
  // Generate the Excel file
  workbook.xlsx.writeBuffer().then(buffer => {
    // Convert buffer to Blob
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Save Blob as file
    saveAs(blob, 'Organization_Reports.xlsx');
  });
}

// to get the key name by passing key's value
function getKeyByValue(obj, value) {
  for (let key in obj) {
    if (obj[key] == value) {
      return key;
    }
  }
  return null;
}

// has object key value in array of object
function hasValueInArrayObjectProp(array, key) {
  return array.some(obj => obj[key]);
}

// merge two array into object
function mergeArraysIntoObject(array, array2) {
  const mergedObject = {};
  array2.forEach((item, index) => {
    // Store only those fields which are in array2
    mergedObject[item.id] = array[index]?.type === 'radio' ? (item?.answers ? item?.answers : '') : item?.answers?.length > 0 ? [...item.answers?.split(',')] : [];
  });

  return mergedObject;
}

function getWeeksToRead(books, searchValue) {
  for (let book of books) {
    if (book.value === searchValue) {
      return book.weeks_to_read;
    }
  }
  return null;
}
function getBookId(books, searchValue) {
  for (let book of books) {
    if (book.value === searchValue) {
      return book.book_id;
    }
  }
  return null;
}


function checkObjectAnyKeyHasValue(obj) {
  return Object.values(obj).some(value => value);
}

export {disableDatesByWeekDay, checkObjectAnyKeyHasValue, isValidZipCode, validateEmail, isEmptyObjectValues, validatePhone, isSpecialCharacterIncludes, isValidFile, formatTime, capitalizeWords, mergeConsecutiveSteps, getOverallGrowthReport, getYearReport, getOrganisationReport, allFieldsHaveValue, getKeyByValue, mergeArraysIntoObject, isValidLocation, getWeeksToRead, hasValueInArrayObjectProp, getBookId};

