import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, Checkbox, RadioGroup, Radio, FormControl, Typography, FormControlLabel, MenuItem } from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import { getAdminGroupsApi, getAdminLeadersGroupApi, getGroupDiscipleApi, getMeetings, updateGroupApi } from '../../api';
import AdminStructure from './common/AdminStructure';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import { capitalizeWords } from '../../utils';
import { CONSTANTS } from '../../constants';
import useTranslations from '../../hooks/useTranslation';
import AddGroupModal from './common/AddGroupModal';
import Modal from '../../components/common/Modal';
import AddDisciple from './components/AddDisciple';
import StyledSelectInput from './components/StyledSelectInput';
import useAuthStore from '../../store/authStore';
function Groups() {
  const { translate } = useTranslations();
  const [selectedGroup, setSelectedGroup] = useState();
  const [groups, setGroups] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [disciples, setDisciples] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [searchGroups, setSearchGroups] = useState([]);
  const [groupLeader, setGroupLeader] = useState();
  const [loader, setLoader] = useState(false);
  const { selectedOrganization, token } = useAuthStore(state => state)
  const [selectDisciple, setSelectDisciple] = useState('');
  const [invite, setInvite] = useState(false);
  const [isAddDiscipleOpen, setIsAddDiscipleOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deleteOption, setDeleteOption] = useState('');
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);

  const handleGroupStatusUpdate = async (groupId, status) => {
    try {
      let payload;
      // Determine the payload based on the status
      switch (status) {
        case 'ARCHIEVED':
          payload = { isMarkGroupAsArchived: true };
          break;
        case 'DELETED':
          payload = { isMarkGroupAsDeleted: true };
          break;
        default:
          return;
      }
      const res = await updateGroupApi(groupId, payload);
      if (res.data.status === 200) {
        handleMenuClose();
        if (reloadData) {
          reloadData();
        }
      }
    } catch (error) {
    } finally {
      handleMenuClose();
    }
  };
  const newDisciples = ['Doug’s Demo One', 'Dis Two', 'Dis Three']; // Example group list

  // Handler for Deleting the group (opens confirmation modal)
  const handleDeleteGroup = (e, itemId) => {
    handleGroupStatusUpdate(itemId, 'DELETED');
    e.stopPropagation();
  };

  const handleDiscipleSave = (selectDisciple, invite) => {
    setIsAddDiscipleOpen(false);
  };

  const handleAddDiscipleGroupChange = event => {
    setSelectDisciple(event.target.value);
  };

  const handleAddDiscipleCheckboxChange = event => {
    setInvite(event.target.checked);
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
    setSelectedItemId(null);
    setIsAddDiscipleOpen(false);
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  // const [open, setOpen] = useState(false);
  const handleOpen = () => setIsAddGroupModalOpen(true);

  const handleGroupClick = async group => {
    groupMeetingsById(group?.id);
    getGroupDisciplesById(group?.id);
    getGroupLeader(group?.id);
    setSelectedGroup(group);
  };

  useEffect(() => {
    if (groups?.length > 0) {
      handleGroupClick(groups[0]);
    }
  }, [groups]);

  const getGroups = async () => {
    try {
      setLoadingPage(true);
      const resp = await getAdminGroupsApi(selectedOrganization.id);
      if (resp?.status == 200) {
        setGroups(resp?.data?.data);
        setSearchGroups(resp?.data?.data);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  const groupMeetingsById = async id => {
    setLoader(true);
    try {
      const res = await getMeetings(id);
      if (res?.data?.status === 200) {
        setMeetings(res?.data?.data);
      } else {
        setMeetings({});
      }
      setLoader(false);
    } catch (error) {
      setMeetings({});
    }
    setLoader(false);
  };

  const getGroupDisciplesById = async id => {
    setLoader(true);
    try {
      const res = await getGroupDiscipleApi(id);
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data);
      }
      setLoader(false);
    } catch (error) { }
    setLoader(false);
  };

  const getLeaders = async () => {
    try {
      const res = await getAdminLeadersGroupApi(selectedOrganization.id);
      if (res?.data?.status == 200) {
        setLeaders(res?.data?.data);
      }
    } catch (error) { }
  };

  const getGroupLeader = async id => {
    try {
      const resp = await getAdminLeadersGroupApi(id, 'leader');
      if (resp?.data?.status == 200) {
        setGroupLeader(resp?.data?.data);
      }
    } catch (error) { }
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setGroups(searchGroups);
    } else {
      const filteredGroups = searchGroups.filter(group => group?.name?.toLowerCase().includes(value.toLowerCase()));
      setGroups(filteredGroups);
    }
  };

  useEffect(() => {
    getGroups();
    getLeaders();
  }, [selectedOrganization]);

  // Calculate the counts of each status group
  const activeGroupsCount = groups ? groups.filter(group => group.status === 'ACTIVE').length : 0;
  const completedGroupsCount = groups ? groups.filter(group => ['COMPLETED'].includes(group.status)).length : 0;

  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: isSmallScreen ? 'auto' : 'hidden'
          }}
        >
          <AdminStructure isSearch isAddingGroup handleAddClick={() => handleOpen()} searchHandler={searchHandler} heading={translate('Groups')}>
            {loadingPage ? (
              <Loader color="#9e0001" />
            ) : groups?.length === 0 ? (
              <NoDataFoundMessage mt={5} message={translate('There is no group for this organization')} />
            ) : (
              <>
                <Box
                  display={'flex'}
                  px={2}
                  gap={2}
                  sx={{
                    height: {
                      md: '88vh',
                      xs: 'auto',
                      sm: 'auto'
                    },
                    flexDirection: {
                      md: 'row',
                      sm: 'column',
                      xs: 'column'
                    },
                    justifyContent: {
                      md: 'space-between'
                    }
                  }}
                >
                  <Box
                    width="85%"
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    sx={{
                      marginTop: {
                        xs: '6px',
                        sm: '6px',
                        md: 0
                      }
                    }}
                  >
                    <ItemList isCompleted items={groups?.filter(group => ['ACTIVE'].includes(group.status))} handleClick={handleGroupClick} selectedItem={selectedGroup} height={'70%'} title={`${translate('Active Groups')} (${activeGroupsCount})`} moreVert={false} reloadData={getGroups} />
                    <ItemList isCompleted items={groups?.filter(group => ['COMPLETED'].includes(group.status))} handleClick={handleGroupClick} selectedItem={selectedGroup} height={'30%'} title={`${translate('Completed Groups')} (${completedGroupsCount})`} moreVert={false} reloadData={getGroups} />
                  </Box>
                  <Box
                    width="68%"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{
                      justifyContent: {
                        md: 'space-between'
                      }
                    }}
                  >
                    {selectedGroup && (
                      <>
                        <ItemDetails
                          leaders={leaders}
                          height="45%" // set height for md screens (xs and sm are defaulted to auto)
                          title={selectedGroup?.name}
                          level={selectedGroup?.level}
                          // change later
                          // editIcon={true}
                          // deleteIcon={true}
                          selectedGroup={selectedGroup}
                          setIsModalOpen={setIsModalOpen}
                          details={[
                            {
                              name: translate('Start Date: '),
                              detail: meetings?.starting_time ? moment(meetings?.starting_time).format(CONSTANTS.DATE_FORMAT) : 'NA',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Day of Week: '),
                              detail: meetings?.meeting_day ? capitalizeWords(meetings?.meeting_day) : 'NA',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Location: '),
                              detail: meetings?.meeting_place ? meetings?.meeting_place : 'NA',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Number of Weeks: '),
                              detail: meetings?.meeting_weeks ? meetings?.meeting_weeks : 'NA',
                              mb: '8px',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Completed: '),
                              detail: meetings?.completed_disciples?.length ? meetings?.completed_disciples?.length : '0',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Dropped: '),
                              detail: meetings?.dropped_disciples?.length ? meetings?.dropped_disciples?.length : '0',
                              mb: '8px',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Leader: '),
                              icon: selectedGroup?.status !== 'COMPLETED',
                              leaders: groupLeader,
                              fontWeight: '600'

                            }
                          ]}
                          loader={loader}
                          setSelectedGroup={setSelectedGroup}
                        />
                        <ItemList 
                          title={`${selectedGroup?.name} ${translate('Disciples')}`} 
                          items={disciples} 
                          handleClick={() => { }} 
                          selectedItem={() => { }} 
                          height="50%" 
                          marginBottom={isSmallScreen ? 2 : 0} 
                          loader={loader} 
                          // change later
                          // isAddingDisciple={true} 
                          // showDeleteIcon={true} 
                          handleAddClick={() => setIsAddDiscipleOpen(true)} 
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </AdminStructure>
        </div>
      </Box>
      <AddGroupModal open={isAddGroupModalOpen} setOpen={setIsAddGroupModalOpen} getData={getGroups} />

      {/* modal for Add disciple to group */}
      <Modal
        open={isAddDiscipleOpen}
        onClose={handleMenuClose}
        title={translate('Add Disciple to New Group')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          { name: translate('Cancel'), onClick: () => setIsAddDiscipleOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined' },
          {
            name: translate('Save'),
            onClick: () => handleDiscipleSave(selectDisciple, notify),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !selectDisciple
          }
        ]}
      >
        <FormControl fullWidth>
          <StyledSelectInput labelId="disciple-select-label" id="disciple-select" value={selectDisciple} onChange={handleAddDiscipleGroupChange} displayEmpty>
            {newDisciples.map((disciple, index) => (
              <MenuItem
                key={index}
                value={disciple}
                sx={{
                  backgroundColor: 'white !important',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#f5f5f5 !important'
                  }
                }}
              >
                {disciple}
              </MenuItem>
            ))}
          </StyledSelectInput>
        </FormControl>
        <Typography variant="body2" sx={{ mt: -2, color: '#888888' }}>
          {translate('Select Existing Disciple')}
        </Typography>
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              color: '#000000 !important'
            }
          }}
          control={
            <Checkbox
              checked={invite}
              onChange={handleAddDiscipleCheckboxChange}
              sx={{
                color: '#000000 !important', // Unchecked color
                '&.Mui-checked': {
                  color: '#000000 !important' // Checked color
                }
              }}
            />
          }
          label={translate('Send Invite to Disciple')}
        />
        <Typography fontWeight="800" fontSize={'16px'} color={'#000000'} mb={2}>
          {translate('OR')}
        </Typography>
        <Typography fontWeight="800" fontSize={'16px'} color={'#d32f2f'} mb={2}>
          {translate('Invite New Disciple')}
        </Typography>
        <AddDisciple />
      </Modal>

      {/* NEW DELETE GROUP MODAL FOR PHASE 3 */}
      <Modal
        open={isModalOpen}
        onClose={handleMenuClose}
        title={translate('Delete Group')}
        modalbgColor={'#ffffff'}
        modalTextColor={'#000000'}
        modalTitleColor={'#d32f2f'}
        buttons={[
          { name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined' },
          {
            name: translate('Delete'),
            onClick: e => handleDeleteGroup(e, selectedItemId),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !deleteOption
          } // Disable Delete if no option is selected
        ]}
      >
        <Typography sx={{ mb: 2, color: '#000000' }}>{translate('If you are simply deleting a group, make sure you have moved disciples to new groups before proceeding.')}</Typography>
        <Typography sx={{ mb: 2, color: '#000000' }}>{translate('Are you certain that you want to delete this group? This action cannot be undone!')}</Typography>
        <FormControl component="fieldset" sx={{ '& .MuiFormControlLabel-label': { color: '#000000', fontSize: '1rem' } }}>
          <RadioGroup
            value={deleteOption}
            onChange={e => setDeleteOption(e.target.value)}
            sx={{
              color: '#000000 !important',
              '& .MuiFormControlLabel-label': {
                color: '#000000 !important'
              }
            }}
          >
            <FormControlLabel
              value="deleteGroup"
              control={
                <Radio
                  sx={{
                    color: '#000000 !important',
                    '&.Mui-checked': {
                      color: '#000000 !important'
                    }
                  }}
                />
              }
              label={translate('Only delete group and group data.')}
            />
            <FormControlLabel
              value="deleteGroupAndDisciples"
              control={
                <Radio
                  sx={{
                    color: '#000000 !important',
                    '&.Mui-checked': {
                      color: '#000000 !important'
                    }
                  }}
                />
              }
              label={translate('Delete group and all disciples from database.')}
            />
          </RadioGroup>
        </FormControl>
      </Modal>
    </>
  );
}

export default Groups;
