import React, {useState} from 'react';
import {Box, Button, IconButton, List, ListItemButton, ListItemText, Typography, Menu, MenuItem, useTheme, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {deleteUser, updateGroupApi} from '../../../api';
import Modal from '../../../components/common/Modal';
import Loader from '../../../components/common/Loader';
import useTranslations from '../../../hooks/useTranslation';
import AddIcon from '@mui/icons-material/Add';

const ItemList = ({isCompleted, isInvited, items, handleClick, selectedItem, height, title, deleteIcon = false, reloadData, loader, marginBottom, moreVert = false, isAddingDisciple = false, handleAddClick, showDeleteIcon = false, openActivateModal, width = '100%'}) => {
  const {translate} = useTranslations();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalArchieveOpen, setIsModalArchieveOpen] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [user, setUser] = useState(selectedItem);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deleteOption, setDeleteOption] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleOpenModal = item => {
    setToDelete(item);
    setIsModalOpen(true);
  };

  const handleMenuOpen = (e, itemId) => {
    setAnchorEl(e.currentTarget);
    setSelectedItemId(itemId);
    e.stopPropagation();
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    setSelectedItemId(null);
    setIsModalArchieveOpen(false);
    setIsModalDeleteOpen(false);
  };

  const handleCloseModal = () => {
    setToDelete(null);
    setIsChecked(false);
    setIsModalOpen(false);
  };

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleGroupStatusUpdate = async (groupId, status) => {
    try {
      let payload;
      // Determine the payload based on the status
      switch (status) {
        case 'ARCHIEVED':
          payload = {isMarkGroupAsArchived: true};
          break;
        case 'DELETED':
          payload = {isMarkGroupAsDeleted: true};
          break;
        default:
          return;
      }
      const res = await updateGroupApi(groupId, payload);
      if (res.data.status === 200) {
        handleMenuClose();
        if (reloadData) {
          reloadData();
        }
      }
    } catch (error) {
    } finally {
      handleMenuClose();
    }
  };
  const handleArchiveModal = e => {
    setIsModalArchieveOpen(true);
    e.stopPropagation();
  };
  const handleDeleteModal = e => {
    setIsModalDeleteOpen(true);
    e.stopPropagation();
  };

  // Handler for Archiving the group
  const handleArchiveGroup = (e, itemId) => {
    handleGroupStatusUpdate(itemId, 'ARCHIEVED');
    e.stopPropagation();
  };

  // Handler for Deleting the group (opens confirmation modal)
  const handleDeleteGroup = (e, itemId) => {
    handleGroupStatusUpdate(itemId, 'DELETED');
    e.stopPropagation();
  };

  const handleConfirmDelete = () => {
    if (toDelete) {
      handleDelete(toDelete);
      handleCloseModal();
    }
  };

  const handleDelete = async id => {
    try {
      const res = await deleteUser(id);
      if (res?.data?.status === 200) {
        if (reloadData) {
          reloadData();
        }
      }
      // Refresh or reload the page after a successful delete
      window.location.reload();
    } catch (error) {}
  };
  return (
    <Box width={width} height={height} overflow="auto" bgcolor="#FFF" borderRadius="12px" marginBottom={marginBottom}>
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          {title && (
            <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800" textAlign="left" pl={3} pt={3}>
              {title}
            </Typography>
          )}
          {isAddingDisciple && (
            <Box
              sx={{
                display: {md: 'flex', xs: 'none', sm: 'none'},
                ml: 2.5
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                onClick={handleAddClick}
                sx={{
                  height: '30px',
                  pl: '1.5px',
                  '&:hover': {
                    borderRadius: '12px',
                    height: '30px'
                  }
                }}
              >
                <AddIcon
                  sx={{
                    color: '#D0373B',
                    fontSize: '20px'
                  }}
                />{' '}
                <Typography style={{fontSize: '14.4px'}} color="black" ml={0.3}>
                  {translate('Add Disciples')}
                </Typography>
              </IconButton>
            </Box>
          )}
          <List>
            {items &&
              items.length !== 0 &&
              items.map((item, index) => (
                <ListItemButton
                  key={index}
                  selected={selectedItem === item}
                  onClick={handleClick ? () => handleClick(item) : null} // Disable handleClick for "Invited Disciples"
                  sx={{
                    paddingLeft: '25px',
                    '&.Mui-selected': {
                      color: '#d32f2f'
                    },
                    height: '30px',
                    '&:hover': {
                      backgroundColor: handleClick ? '#f9f9f9' : 'transparent'
                    },
                    cursor: isInvited ? 'default' : 'pointer' // Change cursor to default if disabled
                  }}
                >
                  <ListItemText
                    primary={
                      <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span style={{display: 'flex', alignItems: 'center', fontWeight: '600'}}>
                          {item?.name ? item?.name : item?.first_name || "" + item?.last_name || ""}
                          {item?.level && <span style={{marginLeft: '8px'}}>{`(${item.level})`}</span>}{' '}
                        </span>
                        <div>
                          {isCompleted && <span style={{marginRight: '8px', color: '#000000', fontWeight: '600'}}>{item?.disicple_count}</span>}
                          {isInvited && (
                            <span
                              style={{marginRight: '8px', display: 'flex', alignItems: 'center'}}
                              onClick={() => {
                                openActivateModal(item);
                              }}
                            >
                              <AddIcon sx={{color: '#d32f2f'}} /> {translate('Activate Disciple')}
                            </span>
                          )}
                          {showDeleteIcon && (
                            <IconButton onClick={() => handleOpenModal(item?.user_id)} style={{marginLeft: '8px', cursor: 'pointer'}}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      </span>
                    }
                    primaryTypographyProps={{
                      sx: {
                        color: '#000'
                      }
                    }}
                  />
                  {moreVert && (
                    <>
                      <IconButton style={{color: '#d32f2f'}} onClick={e => handleMenuOpen(e, item.user_id || item.id)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedItemId === (item.user_id || item.id)}
                        onClose={handleMenuClose}
                        sx={{
                          '& .MuiPaper-root': {
                            backgroundColor: 'white',
                            color: 'black'
                          }
                        }}
                      >
                        <MenuItem style={{backgroundColor: 'white', color: 'black'}} onClick={e => handleArchiveModal(e)}>
                          {translate('ARCHIEVE GROUP')}
                        </MenuItem>
                        <MenuItem style={{backgroundColor: 'white', color: 'black'}} onClick={e => handleDeleteModal(e)}>
                          {translate('DELETE GROUP')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                  {deleteIcon === 'true' && (
                    <IconButton>
                      <DeleteIcon style={{color: '#d32f2f'}} onClick={() => handleOpenModal(item.user_id || item.id)} />
                    </IconButton>
                  )}
                </ListItemButton>
              ))}
          </List>

          <Modal
            modalbgColor={'#ffffff'}
            modalTextColor={'#000000'}
            modalTitleColor={'#d32f2f'}
            open={isModalOpen}
            onClose={handleCloseModal}
            title={translate('Delete Disciple')}
            buttons={[
              {name: translate('Cancel'), onClick: handleCloseModal, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
              {
                name: translate('Delete'),
                onClick: handleConfirmDelete,
                buttonBgColor: '#d32f2f',
                buttonTextColor: '#ffffff',
                buttonHoverColor: '#b71c1c',
                disabled: !isChecked
              }
            ]}
          >
            {/* <Box> */}
            <Typography sx={{color: '#000000'}}>
              {translate('Are you certain that you want to delete this disciple? All information will be removed from the system. This action cannot be undone!')}
              {/* {user?.name} */}
            </Typography>
            <FormControlLabel
              sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '15px', fontWeight: 550}, mb: -1}}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: '#000000',
                    '&.Mui-checked': {
                      color: '#000000'
                    }
                  }}
                />
              }
              label={translate('I am sure that I want to delete this user.')}
            />
          </Modal>
          {/* For Archieve Group */}
          <Modal modalbgColor={'#ffffff'} modalTextColor={'#000000'} modalTitleColor={'#d32f2f'} open={isModalArchieveOpen} onClose={handleMenuClose} title={translate('Confirm Archieve the Group')}>
            <Box>
              <Typography variant="body1" mb={4} color="#000000">
                {translate('Are you sure you want to archieve the group?')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={handleMenuClose} sx={{mr: 1}}>
                  {translate('Cancel')}
                </Button>
                <Button variant="contained" color="secondary" onClick={e => handleArchiveGroup(e, selectedItemId)}>
                  {translate('Archieve')}
                </Button>
              </Box>
            </Box>
          </Modal>
          {/* For Delete Group */}
          {/* <Modal backgroundColor={modalBgColor} color={modalTextColor} open={isModalDeleteOpen} onClose={handleMenuClose} title={translate('Confirm Delete the Group')}>
            <Box>
              <Typography variant="body1" mb={4}>
                {translate('Are you sure you want to delete the group?')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={handleMenuClose} sx={{mr: 1}}>
                  {translate('Cancel')}
                </Button>
                <Button variant="contained" color="secondary" onClick={e => handleDeleteGroup(e, selectedItemId)}>
                  {translate('Delete')}
                </Button>
              </Box>
            </Box>
          </Modal> */}
          {/* NEW DELETE GROUP MODAL FOR PHASE 3 */}
          <Modal
            open={isModalDeleteOpen}
            onClose={handleMenuClose}
            title={translate('Delete Group')}
            modalbgColor={'#ffffff'}
            modalTextColor={'#000000'}
            modalTitleColor={'#d32f2f'}
            buttons={[
              {name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
              {
                name: translate('Delete'),
                onClick: e => handleDeleteGroup(e, selectedItemId),
                buttonBgColor: '#d32f2f',
                buttonTextColor: '#ffffff',
                buttonHoverColor: '#b71c1c',
                disabled: !deleteOption
              } // Disable Delete if no option is selected
            ]}
          >
            <Typography sx={{mb: 2, color: '#000000'}}>{translate('If you are simply deleting a group, make sure you have moved disciples to new groups before proceeding.')}</Typography>
            <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this group? This action cannot be undone!')}</Typography>
            <FormControl component="fieldset" sx={{'& .MuiFormControlLabel-label': {color: '#000000'}}}>
              <RadioGroup
                value={deleteOption}
                sx={{
                  color: '#000000 !important',
                  '& .MuiFormControlLabel-label': {
                    color: '#000000 !important'
                  }
                }}
                onChange={e => setDeleteOption(e.target.value)}
              >
                <FormControlLabel
                  value="deleteGroup"
                  control={
                    <Radio
                      sx={{
                        color: '#000000 !important',
                        '&.Mui-checked': {
                          color: '#000000 !important'
                        }
                      }}
                    />
                  }
                  label={translate('Only delete group and group data.')}
                />
                <FormControlLabel
                  value="deleteGroupAndDisciples"
                  control={
                    <Radio
                      sx={{
                        color: '#000000 !important',
                        '&.Mui-checked': {
                          color: '#000000 !important'
                        }
                      }}
                    />
                  }
                  label={translate('Delete group and all disciples from database.')}
                />
              </RadioGroup>
            </FormControl>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default ItemList;
