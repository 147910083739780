import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';

const authStore = (set, get) => ({
  user: null,
  token: null,
  preferedTimezone: null,
  rememberEmail: '',
  selectedOrganization: null,
  org_to_theme: null,
  theme: null,

  loginUser: userData => {
    set(state => ({
      ...state,
      user: userData,
      token: userData.authToken,
      preferedTimezone: userData.prefered_timezone
    }));
  },
  logoutUser: () => {
    set(state => ({
      ...state,
      user: null,
      token: null,
      preferedTimezone: null,
      selectedOrganization: null
    }));
  },
  setRememberEmail: email => {
    set(state => ({
      ...state,
      rememberEmail: email
    }));
  },
  updateUserData: data => {
    set(state => ({
      ...state,
      user: {...state.user, ...data}
    }));
  },
  updateOrgDefaultLanguage: data => {
    set(state => ({
      ...state,
      selectedOrganization: {...state.selectedOrganization, ...data}
    }));
  },
  loginNewUser: data => {
    set(state => ({
      ...state,
      token: data?.authToken,
      user: data
    }));
  },
  setOrganization: (data) => {
    set(state => ({
      ...state,
      selectedOrganization: data
    }));
  },
  setOrgToTheme: (data) => {
    set(state => ({
      ...state,
      org_to_theme: data
    }));
  },
  setTheme: (data) => {
    set(state => ({
      ...state,
      theme: data
    }));
  },

  updateOrganizationLogo: (id, avatar) => {
    set(state => ({
      ...state,
      selectedOrganization: {...state.selectedOrganization, avatar: avatar},
      user: {
        ...state.user,
        organizations: state.user?.organizations?.map((org) => {
          if(org.id == id) {
            return {
              ...org,
              avatar: avatar
            }
          }else {
            return org;
          }
        })
      }
    }))
  }
});

const useAuthStore = create(
  persist(authStore, {
    name: 'auth-storage',
    storage: createJSONStorage(() => localStorage)
  })
);

export default useAuthStore;
