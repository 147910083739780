import React, {useState} from 'react';
import {Grid} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from './AdminTextInput';

const AddDisciple = ({formData, setFormData}) => {
  const {translate} = useTranslations();
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <form style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <Grid item xs={12}>
        <AdminTextInput fullWidth name="name" bottomLabel={translate('Disciple Name')} value={formData.name} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput fullWidth name="phone" bottomLabel={translate('Phone')} value={formData.phone} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput fullWidth name="email" bottomLabel={translate('Email')} value={formData.email} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
    </form>
  );
};

export default AddDisciple;
