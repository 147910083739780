import {useState} from 'react';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import 'moment/locale/en-gb';
import useTranslations from "../../hooks/useTranslation";
import { Typography } from '@mui/material';

const TimePickerComponent = ({label, value, onChange, color1="white", color2="black", bottomLabel, className="timePicker"}) => {
  const {translate} = useTranslations();
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
      <DemoContainer sx={{textAlign: 'left'}} components={['MobileTimePicker']}>
        <DemoItem label={translate(label)}>
          <MobileTimePicker
            open={open}
            onClose={() => setOpen(false)}
            slotProps={{
              textField: {
                onClick: () => setOpen(true),
                placeholder: translate("SELECT TIME"),
              }
            }}
            sx={{svg: {color: color1}, color: color2}}
            className={className}
            value={value}
            onChange={onChange}
          />
        </DemoItem>
      </DemoContainer>
      {bottomLabel && <Typography color="gray">{bottomLabel}</Typography>}
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
