import {FormControl, Typography} from '@mui/material';
import React from 'react';
import {styled} from '@mui/material/styles';

const StyledSelect = styled('select')({
  width: '100%',
  padding: '6px',
  margin: '10px 0 20px',
  //   borderRadius: '4px',
  border: '1px solid #000000',
  fontSize: '16px',
  outline: 'none',
  '&:focus': {
    borderColor: '#d32f2f'
  }
});
const StyledSelectInput = ({bottomLabel, labelTopGap, labelVariant, labelColor = '#888888', value, onChange, optionArray = [], placeholder = 'Select'}) => {

  return (
    <FormControl fullWidth>
      {/* <StyledSelect value={value} setValue={setValue} onChange={onChange} optionArray={optionArray} /> */}
      <StyledSelect value={value || ''} onChange={onChange}>
        <option value="" disabled>
          {placeholder}
        </option>
        {optionArray.map((option, index) => (
          <option key={option.value || index} value={option.value} disabled={option.disabled} selected={option.selected}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {bottomLabel && (
        <Typography textAlign="left" color={labelColor} variant={labelVariant} mt={labelTopGap}>
          {bottomLabel}
        </Typography>
      )}
    </FormControl>
  );
};

export default StyledSelectInput;
