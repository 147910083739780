import {useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import {capitalizeWords} from '../../utils';
import CircularProgress from '@mui/material/CircularProgress';

const CustomButton = ({onClick = () => {}, visible = true, text, variant = 'contained', color = 'secondary', disabled = false, type, disablePadding, loading = false, borderRadius = '15px', backgroundColor = '#02140A', textColor = '#fff', hoverColor = '#021209'}) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        fontWeight: 600,
        visibility: visible ? 'visible' : 'hidden',
        borderRadius: borderRadius,
        // backgroundColor: backgroundColor,
        // color: textColor,
        // '&:hover': {backgroundColor: hoverColor},
        // backgroundColor: type === 'outlined' ? 'transparent' : backgroundColor,
        // color: textColor,
        // border: type === 'outlined' ? `1px solid ${textColor}` : 'none'
        // '&:hover': {
        //   backgroundColor: hoverColor,
        //   ...(type === 'outlined' && { backgroundColor: '#f5f5f5' }),
        // },
        backgroundColor: type === 'outlined' ? 'transparent' : backgroundColor,
        color: textColor,
        border: type === 'outlined' ? `1px solid ${textColor}` : 'none', // Conditional border
        '&:hover': {
          backgroundColor: hoverColor,
          ...(type === 'outlined' && { backgroundColor: '#f5f5f5' }), // Conditional hover for outlined
        },
      }}
      type={type === 'submit' ? 'submit' : 'button'}
      style={{marginBlock: disablePadding ? theme.spacing(0) : theme.spacing(2)}}
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={disabled || loading}
      startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
    >
      {capitalizeWords(text)}
    </Button>
  );
};

export default CustomButton;
