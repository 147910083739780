import {useState, useEffect} from 'react';
import {Box, Typography, FormControl, MenuItem, Select, useTheme} from '@mui/material';
import FormWrapper from '../../common/StyledFormWrapper';
import {getOrganizationsApi} from '../../../api';
import useAuthStore from '../../../store/authStore';
import CheckBoxInput from '../../common/CheckBoxInput';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';

const PageTwo = ({nextPage, setGroupData}) => {
  const theme = useTheme();
  const modalBgColor = theme.palette.modal.bgcolor;
  const modalTextColor = theme.palette.modal.textcolor; 
  const {translate} = useTranslations();
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [loading, setLoading] = useState(false);
  const {user: userData} = useAuthStore(state => state);
  const [isPublicAndHasOrganzations, setIsPublicAndHasOrganzations] = useState(userData?.is_public == 1 ? true : false);
  const [pageLoading, setPageLoading] = useState(false);

  const getUsersOrganizations = async () => {
    try {
      setPageLoading(true);
      const response = await getOrganizationsApi(userData?.id, 'organization');
      if (response?.data?.status === 200) {
        if (response?.data?.data?.length > 0) {
          setIsPublicAndHasOrganzations(false);
        }
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  };

  const fetchOrgs = async () => {
    try {
      setLoading(true);
      const response = await getOrganizationsApi(isPublicAndHasOrganzations ? '' : userData?.id, 'organization');
      if (response?.data?.status === 200) {
        setOrganization(response?.data?.data);
        if (response?.data?.data?.length === 1 && selectedOrganization === '') {
          setSelectedOrganization(response?.data?.data[0]?.id);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOrganizationChange = event => {
    setError(false);
    setSelectedOrganization(event.target.value);
  };

  const handleContinue = () => {
    if (isPublicAndHasOrganzations && !checked) {
      nextPage();
      return;
    }
    if (!selectedOrganization) {
      setError(true);
      return;
    }
    setGroupData(prevData => ({
      ...prevData,
      organization_id: selectedOrganization
    }));
    nextPage();
  };

  useEffect(() => {
    getUsersOrganizations();
  }, []);

  useEffect(() => {
    fetchOrgs();
  }, [isPublicAndHasOrganzations]);
  return (
    <FormWrapper buttonInputs={[{text: translate("Continue"), onClick: handleContinue}]} headingText={isPublicAndHasOrganzations ? translate("Are You With an Organization?") : translate("Select your Organization")} loading={loading}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight={300} mt={1}>
        {pageLoading ? (
          <Loader color="#FFFFFF" />
        ) : (
          <>
            {isPublicAndHasOrganzations && (
              <Box display="flex" justifyContent="space-evenly">
                <CheckBoxInput onChange={() => setChecked(true)} checked={checked} label={translate("Yes")} />
                <CheckBoxInput onChange={() => setChecked(false)} checked={!checked} label={translate("No")} />
              </Box>
            )}
            {(checked || !isPublicAndHasOrganzations) && (
              <FormControl sx={{width: '100%', paddingBlock: 1}}>
                <Typography textAlign="left" marginBottom={10} variant="body2">
                  {translate("Pick Your Organization")}
                </Typography>
                <Select placeholder={translate("Select Organization")} value={selectedOrganization} onChange={handleOrganizationChange} displayEmpty sx={{border: 'none'}}>
                  <MenuItem sx={{display: 'none', height: '0px'}} value="" disabled selected>
                    <em>{translate("Select Organization")}</em>
                  </MenuItem>
                  {organization.map((org, index) => {
                    return (
                      <MenuItem style={{color: modalTextColor, background: modalBgColor}} key={org.id} value={org.id}>
                        {org.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Box height="70px" display="flex" flexDirection="column">
                  <Typography textAlign="left" variant="caption">
                    {translate("If you don't see your organization in the drop down,")} <Link to="/contact-us" style={{color:"white", textDecoration: "underline"}}>{translate("Email us")}</Link> {translate("and we'll help you get set-up.")}
                  </Typography>
                  {error && (
                    <Typography mt={2} align="left" fontSize={12} variant="p">
                      {translate("Select the organization")}
                    </Typography>
                  )}
                </Box>
              </FormControl>
            )}
          </>
        )}
      </Box>
    </FormWrapper>
  );
};

export default PageTwo;
