import React from 'react';
import {styled} from '@mui/material/styles';
import {FormControl, Typography} from '@mui/material';

const StyledInput = styled('input')({
  width: '96%',
  padding: '6px',
  border: '1px solid #000000',
  fontSize: '16px',
  outline: 'none',
  '&:focus': {
    borderColor: '#d32f2f'
  },
  '&:disabled': {
    backgroundColor: '#f0f0f0',
    cursor: 'not-allowed'
  }
});

const AdminTextInput = ({value, name, onChange, placeholder, maxLength, disabled = false, readOnly = false, isAllowSpecialChar = true, bottomLabel, labelBottomGap = 2, labelVariant, labelColor = '#888888', error, helperText}) => {
  // Handle input change
  const handleInputChange = e => {
    const inputValue = e.target.value;
    if (isAllowSpecialChar || /^[a-zA-Z0-9\s]*$/.test(inputValue)) {
      onChange(e);
    }
  };

  return (
    <FormControl fullWidth >
      <StyledInput
        value={value}
        name={name}
        onChange={handleInputChange}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        readOnly={readOnly}
      />
      {error && (
        <Typography color="error" variant="body2" sx={{marginTop: '4px'}}>
          {helperText}
        </Typography>
      )}
      {bottomLabel && (
        <Typography textAlign="left" color={labelColor} variant={labelVariant} mb={labelBottomGap}>
          {bottomLabel}
        </Typography>
      )}
    </FormControl>
  );
};

export default AdminTextInput;
