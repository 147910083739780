import React, {useState, useEffect} from 'react';
import {Box} from '@mui/system';
import CheckBoxInput from '../components/common/CheckBoxInput';
import {getAttendenceOfGroupMeeting, markAttendenceOfGroupMeeting} from '../api';
import moment from 'moment';
import useTranslations from '../hooks/useTranslation';

const MarkAttendance = ({fetchGroupDiscipleId, meetingId, currentMeetingDate,previousMeetingDate,nextMeetingDate,isSkipWeek,meetingTime}) => {
  const { translate } = useTranslations();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [label, setLabel] = useState("This Week's Meeting");

// Fetch attendance status on component mount
useEffect(() => {
  const fetchAttendanceStatus = async () => {
    try {
      const res = await getAttendenceOfGroupMeeting(meetingId,fetchGroupDiscipleId);
      setCheckboxChecked(res?.data.data);
    } catch (error) {
    }
  };
  fetchAttendanceStatus();
}, [meetingId, fetchGroupDiscipleId]);

  const handleAttendanceChange = async (isChecked) => {
    const data = {
      meeting_id: meetingId,
      attendese: [fetchGroupDiscipleId],
      status: isChecked ? 'checked' : 'unchecked' 
    };
    try {
      const res = await markAttendenceOfGroupMeeting(data);
      if (res?.data?.status === 200) {
        // navigate(-1);
      }
    } catch (error) {
    }
  };
  const getCheckboxLabel = () => {
    let currentDate = new Date(); // Today's date at 00:00:00
    currentDate = currentDate.setHours(0, 0, 0, 0); 
    currentDate = moment(currentDate)
    currentMeetingDate = new Date(moment(currentMeetingDate, "MMMM Do YYYY"))
    currentMeetingDate = currentMeetingDate.setHours(0,0,0,0)
    previousMeetingDate = new Date(moment(previousMeetingDate, "YYYY-MM-DD HH:mm:ss"))
    previousMeetingDate = previousMeetingDate.setHours(0,0,0,0)
    nextMeetingDate = new Date(moment(nextMeetingDate, "YYYY-MM-DD HH:mm:ss"))
    nextMeetingDate = nextMeetingDate.setHours(0,0,0,0)
    const currentMeetingStartDate = moment(currentMeetingDate) // Meeting date
    const nextWeekStartDate = moment(nextMeetingDate)// Next week's start date
    // const previousMeetingStartDate = moment(previousMeetingDate)// Next week's start date
    if(currentDate.isSame(currentMeetingStartDate) || (currentMeetingStartDate.isBefore(currentDate)) && nextWeekStartDate.isAfter(currentDate)) {
       setLabel(translate("I was at the weekly meeting"));
    }else{
       setLabel(`${translate("I was at this weeks meeting - ")}${currentMeetingStartDate.format('Do MMMM YYYY')}`);
    }
    if(moment(new Date()).isBefore(currentMeetingDate)){

            //setLabel(`Future Meeting - ${currentMeetingStartDate.format('DD MMMM YYYY')}`);
      setLabel(`Attendance not available until - ${moment(currentMeetingDate).format('Do MMMM YYYY')} ${moment.utc(meetingTime).local().format('hh:mm A')}`);

      
    }
    
  };
const checkDisable = () => {
    if(!moment(new Date(moment(currentMeetingDate, "MMMM Do YYYY"))).isBefore(new Date())){
      return true;
    }
    return false;
  };
  // Call getCheckboxLabel once when the component mounts to set the label
  useEffect(() => {
    getCheckboxLabel();
  }, [currentMeetingDate]);
 return (
  !isSkipWeek && (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start', // Updated for better alignment
        textAlign: 'left',
        mt: 1,
      }}
    >
      <CheckBoxInput
        label={label}
        checked={checkboxChecked}
        disabled={checkDisable()}
        onChange={checked => {
          setCheckboxChecked(checked);
          handleAttendanceChange(checked); 
        }}
      />
    </Box>
  )
);

  
};

export default MarkAttendance;
