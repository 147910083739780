import UserContainer from '../components/common/UserContainer';
import { Avatar, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { CONSTANTS, FILE_PATH_PROFILE_URL, FILE_PATH_URL } from '../constants';
import { getLeadersGroup, updateGroupApi } from '../api';
import TextInput from '../components/common/TextInput';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import theme from '../styles/theme';
import useAuthStore from '../store/authStore';
import DoneIcon from '@mui/icons-material/Done';
import { getUsersDetails } from '../api/index';
import useLeaderStore from '../store/leaderStore';
import Modal from '../components/common/Modal';
import Loader from '../components/common/Loader';
import useTranslations from '../hooks/useTranslation';

const GroupItem = ({ group, onClick, getGroupDetails }) => {
  const { translate } = useTranslations();
  const [groupUpdating, setGroupUpdating] = useState({ status: '', id: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleGroupStatusUpdate = async () => {
    if (groupUpdating.status !== 'COMPLETED') {
      try {
        const res = await updateGroupApi(groupUpdating.id, { isMarkGroupAsCompleted: true });
        if (res.data.status === 200) {
          setIsModalOpen(false);
          setGroupUpdating({ status: '', id: '' });
          getGroupDetails();
        }
      } catch (error) {
        setIsModalOpen(false);
      }
    }
  };
  return (
    <>
      <Box p={2} sx={{ cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box onClick={onClick} display="flex" alignItems="center">
            <Avatar sx={{ width: 56, height: 56 }} alt={group.name} src={group?.avatar ? `${FILE_PATH_URL}/${group?.avatar}` : ''} />
            <Box textAlign="left" ml={2}>
              <Typography variant="h6" fontWeight={600}>
                {group.name}
              </Typography>
              <Typography variant="body1">{group?.description}</Typography>
              {group?.organization && (
                <Typography variant="body1" fontSize={12}>
                  {translate("Organization: ")}{group?.organization?.length > CONSTANTS.ORGANIZATION_LENGTH ? `${group?.organization}...` : group?.organization}
                </Typography>
              )}
            </Box>
          </Box>
          <IconButton
            title={group?.status !== 'COMPLETED' ? translate("Mark as Completed") : translate("Completed")}
            onClick={() => {
              if (group?.status !== 'COMPLETED') {
                setIsModalOpen(true);
                setGroupUpdating({ status: group?.status, id: group?.id });
              }
            }}
          >
            <DoneIcon sx={{ color: group?.status === 'COMPLETED' ? 'white' : 'gray', fontWeight: 'bolder' }} />
          </IconButton>
        </Box>
      </Box>

      <Modal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        title={translate("Group Status Update")}
        buttons={[
          { name: translate("Close"), onClick: () => setIsModalOpen(false) },
          {
            name: translate("Update"),
            onClick: () => handleGroupStatusUpdate()
          }
        ]}
      >
        <Typography sx={{ color: 'white' }}>{translate("Are you sure you want to mark group as completed?")}</Typography>
      </Modal>
    </>
  );
};

const LeaderHome = () => {
  const { translate,switchLanguage } = useTranslations();
  const [loading, setLoading] = useState(false);
  const [sortedGroups, setSortedGroups] = useState([]);
  const [sortedOrder, setSortedOrder] = useState('desc');
  const [groupsDetails, setGroupDetails] = useState([]);
  const { user, setTheme, org_to_theme } = useAuthStore(state => state);
  const { clearLeaderData } = useLeaderStore(state => state);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const [defaultLang, setDefaultLang] = useState(false);

  const getGroupDetails = async () => {
    try {
      setLoading(true);
      const response = await getLeadersGroup();
      if (response?.data?.status === 200) {
        setGroupDetails(response?.data?.data);
        setSortedGroups(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearchTextInput = e => {
    setSortedGroups(groupsDetails.filter(org => org.name.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  const handleSort = () => {
    setSortedOrder(prevSortOrder => (prevSortOrder == 'desc' ? 'asc' : 'desc'));
    setSortedGroups(prevSortedOrgs => [...prevSortedOrgs].sort((a, b) => (sortedOrder == 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))));
  };
  useEffect(() => {
    clearLeaderData();
  }, []);

  const getUserHandler = async () => {
    try {
      const currentUserData = await getUsersDetails(user?.id);
      const response = currentUserData?.data;
      setLoading(true);
      if (response?.status === 200) {
        setProfileData({
          id: response?.data?.id,
          name: `${response?.data?.first_name} ${response?.data?.last_name === 'undefined' ? '' : response?.data?.last_name}`,
          avatar: response?.data?.avatar !== null ? `${FILE_PATH_PROFILE_URL}/${response?.data?.avatar}` : ''
        });
        // Call switchLanguage only once
      // if (!defaultLang) {
      //   switchLanguage('hi');
      //   setDefaultLang(true)
      // }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserHandler();
  }, []);

  useEffect(() => {
    getGroupDetails();
  }, []);

  const handleSetTheme = (orgId) => {
    const selectedOrg = org_to_theme.find(org => org.org_id == orgId);
    const newThemeId = selectedOrg?.theme_id;
    if (newThemeId) {
      setTheme(newThemeId);
    }
  };

  return (
    <UserContainer onClick={() => navigate(`/create-group`)} addText={translate("Add Group")} addText2={translate("Reading")} isShow2={false} headingText={profileData?.name} filePath={profileData?.avatar} subHeadingText={groupsDetails?.organization}>
      <Box>
        <Box display="flex" alignItems="center" gap={2} pt={2} px={2}>
          <TextInput type="text" placeholder={translate("Search...")} onChange={handleSearchTextInput} />
          <IconButton onClick={handleSort} sx={{ marginBottom: '16px' }}>
            <SortByAlphaIcon
              fontSize="22"
              style={{
                fontSize: '2rem',
                color: theme.palette.error.main
              }}
            />
          </IconButton>
        </Box>
        {loading ? (
          <Loader />
        ) : groupsDetails && groupsDetails?.length > 0 ? (
          <Box>
            {sortedGroups?.map(group => (
              <GroupItem
                key={group?.id}
                group={group}
                onClick={() => {
                  handleSetTheme(group?.organization_id);
                  navigate(`/group/${group?.name}/${group?.id}`);
                  window.location.reload();
                }}
                getGroupDetails={getGroupDetails}
              />
            ))}
          </Box>
        ) : (
          <Box marginTop={10}>
            <Typography variant="h5" marginBottom={4}>
              {translate("No group found")}
            </Typography>
          </Box>
        )}
      </Box>
    </UserContainer>
  );
};

export default LeaderHome;
