import { CONSTANTS } from "../constants";

const useTranslations = () => {
  const loadAllTranslations = async (lan = 'en') => {
    try {
      const response = await fetch(`/locales/${lan}.json`);
      const data = await response.json();
      localStorage.setItem('translation', JSON.stringify(data));
      window.location.reload();

    }
     catch (error) {
      console.error('Error loading translation files:', error);
    }
  };

  const translate = key => {
    const storedTranslations = localStorage.getItem('translation');
    const translations = storedTranslations ? JSON.parse(storedTranslations) : {};
    return translations[key] || key;
  };
  
  const switchLanguage = lang => {
    const selectedLang = CONSTANTS.LANG[lang]
    loadAllTranslations(selectedLang.value);
  };

  return {loadAllTranslations, translate, switchLanguage};
};

export default useTranslations;
